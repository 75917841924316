<template>
  <div id='container'>
     <div class="header">
        <van-nav-bar
        title="推广赚钱"
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onClickLeft"
        />
    </div>
    <div class="content">
        <!--  -->
        <div class="bg-box">
            <img src="../../assets/images/bg.jpg" alt="">
        </div>
        <!--  -->
        <div class="bg-box_1">
            <img src="../../assets/images/bg_1.jpg" alt="">
            <div class="sy_box">
                <div class="info">
                    <div class="info_">
                        <span>{{inviteNum}}</span>
                        <span style="font-size: 12px;color: #ccc;">成功邀请数</span>
                    </div>
                    <div class="info_">
                        <span>{{salary}}</span>
                        <span style="font-size: 12px;color: #ccc;">元宝收益</span>
                    </div>
                    <div class="info_">
                        <span>{{score}}</span>
                        <span style="font-size: 12px;color: #ccc;">获得积分</span>
                    </div>
                </div>
                <div class="button">
                    <div class="button_">
                        <!-- <img src="" alt=""> -->
                        <div class="box"><img src="../../assets/images/m.jpg" alt=""></div>
                        <span style="font-size: 12px;color: #000;">收益明细</span>
                    </div>
                    <div class="button_">
                        <div class="box"><img src="../../assets/images/n.jpg" alt=""></div>
                        <span style="font-size: 12px;color: #000;">去提现</span>
                    </div>
                    <div class="button_">
                        <div class="box"><img src="../../assets/images/o.jpg" alt=""></div>
                        <span style="font-size: 12px;color: #000;">推广收益</span>
                    </div>
                    <div class="button_">
                        <div class="box" @click="goTg"><img src="../../assets/images/p.jpg" alt=""></div>
                        <span style="font-size: 12px;color: #000;">去推广</span>
                    </div>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="banner">
            <img src="../../assets/images/banner.jpg" alt="">
        </div>
        <!--  -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { Toast } from 'vant';

export default {
    data() {
        return {
            inviteNum:'',
            score:"",
            salary:"",
        }
    },
    created() {
        this.inviteNum = this.userInfo.inviteNum
        this.score = this.userInfo.score
        this.salary = this.userInfo.salary


    },
    computed:{
        // ...mapMutations(["changeFlag","changeFlag1"]),
          ...mapState({
            loginStatus : state=>state.user.loginStatus,
            userInfo : state=>state.user.userInfo
        }),
        loginStatus(){
          return this.$store.state.user.loginStatus
        },
        userInfo(){
          return this.$store.state.user.userInfo
        },

    },
    methods: {
        onClickLeft(){
            this.$router.go(-1)
        },
        goTg(){
            if(this.loginStatus){
                this.$router.push({
                    path:"/QrCode"
                })
            }else{
                 Toast.fail('请先登录！')
            }
        }
    },







    async mounted() {
    // this.ces = true
      // this.ces = true
        // this.changeFlag();
        this.$store.commit('changeFlag')
  },
  destroyed() {
      // this.changeFlag1();
      this.$store.commit('changeFlag1')
  },
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
#container{
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;

}
#container .header{
    width: 100%;
    height: 60px;
    /* color: #ec4899; */
}
#container .logo{
    position: absolute;
    top: 10px;
    left: 34%;
    z-index: 99;
}
#container .logo img{
    height: 45px;
}
#container .content{
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: scroll;
    padding: 0;
    box-sizing: border-box;
}

 /deep/.van-nav-bar__text{
   
    color: #ec4899 !important;
}
 /deep/.van-nav-bar__title{
    color: #ec4899 !important;
    font-size: 18px;
}
/deep/.van-nav-bar .van-icon{
    color: #ec4899 !important;
}
/deep/.van-nav-bar__content{
    height: 60px !important;
}
.content .bg-box{
    width: 100%;
    height: 220px;
}
.content .bg-box img{
    width: 100%;
    height: 100%;
}
.content .bg-box_1{
    width: 100%;
    height: 280px;
    position: relative;
}
.content .bg-box_1 img{
    width: 100%;
    height: 100%;
}
.content .bg-box_1 .sy_box{
    position: absolute;
    left: 23px;
    top: 55px;
    width: calc(100% - 50px);
    height: calc(100% - 90px);
    /* background-color: #ec4899; */
    /* border: 2px solid #f5f5f5; */
}
.content .bg-box_1 .sy_box .info{
    width: 100%;
    height: 70px;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}
.content .bg-box_1 .sy_box .info .info_{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.content .bg-box_1 .sy_box .info .info_ span{
    font-size: 22px;
}
.content .bg-box_1 .sy_box .button{
    width: 100%;
    height: 80px;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}
.content .bg-box_1 .sy_box .button .button_{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
}
.content .bg-box_1 .sy_box .button .button_ .box img{
    width: 40px;
    height: 40px;
}
.content .banner{
    width: 100%;
    height: 480px;
}
.content .banner img{
    width: 100%;
    height: 100%;
}
</style>